import { IconProps } from './types'

export const SupportedProjects = ({ className }: IconProps) => {
  return (
    <svg
      width='36'
      height='31'
      viewBox='0 0 36 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M9.10047 29.4517C9.88698 29.3955 16.1229 29.5393 17.078 29.4517C17.8074 29.3848 19.6623 29.1708 20.8982 29.0584C21.6847 29.0023 23.7297 28.8562 26.0668 27.5978C28.9881 26.0247 31.0106 24.3955 32.5274 23.1596C34.0443 21.9236 34.269 21.3056 34.3252 20.9124C34.3814 20.5191 33.9319 19.9011 33.2016 19.9011C32.4713 19.9011 31.7409 20.2944 31.46 20.4629C31.2353 20.5978 29.681 21.6053 28.9319 22.0922C28.3327 22.5416 26.8196 23.6202 25.5611 24.3393C24.3027 25.0584 22.49 25.1259 21.7409 25.0697C20.8046 25.0697 18.5836 25.0584 17.1904 25.0135C15.4488 24.9573 14.7746 23.8337 15.0555 22.7663C15.2802 21.9124 16.4038 21.418 17.5836 21.418C19.0817 21.3805 21.6286 21.5304 22.8645 20.8C23.632 20.3465 23.1454 20.0135 22.8645 19.845C22.5635 19.6644 21.4787 19.4892 20.9544 19.4517C20.4675 19.433 18.8645 19.4068 16.3477 19.4517C13.2016 19.5079 11.7409 19.7326 10.2241 20.0135C8.70721 20.2944 7.24653 21.5866 6.29148 22.4293C5.52743 23.1034 4.77462 23.2345 4.49372 23.2158C4.13792 23.2158 3.24653 23.2495 2.52743 23.3843C1.80833 23.5191 1.5911 23.8899 1.57238 24.0585C1.49747 24.4517 1.34766 25.5865 1.34766 26.9798C1.34766 28.7214 1.62856 29.0023 2.13417 29.3393C2.21844 29.3955 5.20159 29.4967 6.68474 29.4517'
        stroke='currentColor'
        stroke-width='1.5'
        stroke-linecap='round'
      />
      <path
        d='M16.5152 13.038L15.9391 10.4638L15.0747 12.956C14.8832 13.5082 14.8189 14.0029 14.8301 14.3941C14.8356 14.5871 14.86 14.7723 14.9069 14.9349C14.93 15.015 14.9642 15.1092 15.0168 15.2021C15.0592 15.277 15.1634 15.4418 15.3666 15.5488L15.5306 15.6351H15.716C16.0498 15.6351 16.244 15.4257 16.2821 15.3844C16.3477 15.313 16.3879 15.2431 16.4081 15.2054C16.4517 15.124 16.4812 15.0409 16.5012 14.9762C16.5437 14.8387 16.5777 14.6699 16.5992 14.4831C16.6425 14.1063 16.6411 13.6005 16.5152 13.038ZM16.1236 8.80633L15.8026 10.1758L17.1186 9.67918C20.7077 8.32479 22.3988 5.67206 22.8088 5.02889C22.8347 4.98812 22.8556 4.95543 22.8714 4.93165C23.0033 4.73393 23.0851 4.46553 23.1336 4.24588C23.1878 4.00081 23.2243 3.70611 23.2153 3.40588C23.2065 3.1136 23.1529 2.76017 22.9804 2.43932C22.7942 2.09324 22.4735 1.79939 22.0052 1.69592C21.4085 1.56407 20.8888 1.60506 20.3535 1.8455C19.8743 2.0607 19.404 2.42968 18.8791 2.85619C18.0688 3.51455 17.4708 4.70746 17.0469 5.78868C16.6079 6.90846 16.2964 8.06885 16.1236 8.80633ZM9.60665 3.19917L9.58676 3.2111L9.56765 3.22425C9.29521 3.41154 8.95345 3.75127 8.73512 4.21423C8.50599 4.70007 8.41206 5.33086 8.69302 6.0132C9.22376 7.30213 10.8479 9.72933 14.0176 10.6876L14.9846 10.98V9.96971C14.9846 9.21033 14.8454 8.00508 14.4342 6.77435C14.0283 5.5597 13.3319 4.24481 12.1644 3.33914C11.7158 2.92635 10.7213 2.53032 9.60665 3.19917ZM15.0654 14.5413C15.0654 14.5413 15.0657 14.5404 15.0664 14.5387C15.0657 14.5405 15.0654 14.5414 15.0654 14.5413Z'
        stroke='currentColor'
        stroke-width='1.5'
      />
      <path
        d='M28.3816 12.6057C28.7816 12.771 29.0764 12.9004 29.1934 12.9654C29.2074 12.9732 29.2201 12.9806 29.2318 12.9875C29.2161 12.9847 29.2015 12.9816 29.1884 12.9785C28.9565 12.8735 28.6791 12.7457 28.3816 12.6057ZM29.4208 12.9962C29.4209 12.9962 29.4202 12.9963 29.419 12.9966L29.4208 12.9962Z'
        stroke='currentColor'
        stroke-width='1.61603'
      />
      <path
        d='M27.9292 8.68534C28.3052 8.66815 28.6989 8.65154 29.0779 8.63774C30.0101 8.6038 30.7706 8.59069 31.0101 8.61309C31.1016 8.62164 31.1605 8.6339 31.196 8.64378C31.2015 8.66813 31.2069 8.69918 31.2118 8.73898C31.1872 8.74916 31.1589 8.7593 31.1274 8.76873C31.0841 8.78168 31.0433 8.79073 31.01 8.79653C30.3241 8.78897 29.3001 8.76411 28.384 8.71342C28.2274 8.70476 28.0752 8.6954 27.9292 8.68534Z'
        stroke='currentColor'
        stroke-width='1.61603'
      />
      <path
        d='M29.7516 3.57069C29.7332 3.5942 29.7151 3.61494 29.6992 3.63181C29.4375 3.82016 29.1081 4.05319 28.7547 4.29733C29.2008 3.94941 29.5444 3.69275 29.6823 3.60981C29.7087 3.59393 29.7317 3.58108 29.7516 3.57069ZM29.8336 3.43429C29.8336 3.43428 29.8335 3.43486 29.8331 3.43605L29.8336 3.43429Z'
        stroke='currentColor'
        stroke-width='1.61603'
      />
    </svg>
  )
}
