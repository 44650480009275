import { IconProps } from './types'

export const HelpCentre = ({ className }: IconProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.5 1C4.567 1 3 2.567 3 4.5V15.5C3 17.433 4.567 19 6.5 19H16C16.5523 19 17 18.5523 17 18V17.5C17 17.3094 16.9278 17.1188 16.7834 16.9728L16.7811 16.9704L16.7819 16.9712C16.7789 16.9681 16.7727 16.9614 16.7636 16.9514C16.7454 16.9313 16.7162 16.8977 16.6794 16.8518C16.6055 16.7594 16.5036 16.6197 16.4012 16.4404C16.1945 16.0787 16 15.5822 16 15C16 14.4178 16.1945 13.9213 16.4012 13.5596C16.5036 13.3803 16.6055 13.2406 16.6794 13.1482C16.7162 13.1023 16.7454 13.0687 16.7636 13.0486C16.7727 13.0386 16.7789 13.0319 16.7819 13.0288L16.7834 13.0272C16.9278 12.8812 17 12.6906 17 12.5V2C17 1.44772 16.5523 1 16 1H6.5ZM15.2955 12.5H7C5.5 12.5 4.5 13 4.5 15C4.5 17 5.5 17.5 7 17.5H15.2955C15.2319 17.4064 15.1653 17.301 15.0988 17.1846C14.9206 16.8727 14.7379 16.4722 14.6219 16H7C6.44772 16 6 15.5523 6 15C6 14.4477 6.44772 14 7 14H14.6219C14.7379 13.5278 14.9206 13.1273 15.0988 12.8154C15.1653 12.699 15.2319 12.5936 15.2955 12.5Z'
        fill='currentColor'
      />
    </svg>
  )
}
